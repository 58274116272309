import React from 'react';
import {Text} from "@gs-ux-uitoolkit-react/text";
import {TeamsTable} from "../../Table/TeamsTable";
import {TeamsTableCell} from "../../Table/TeamsTableCell";
import parse from 'html-react-parser';
import {
    additionalContextsWithDisplayNames,
    getAdditionalContextWithoutOtherChanges,
    returnCommaIfNotLastValue
} from "../../../services/utils/AdditionalContextFormatter";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {AdditionalContextPanelStyleSheet} from "./AdditionalContextPanelStyleSheet";

interface AdditionalContextPanelProps {
    additionalContextsResponse: any;
    requestDetailsResponse: any;
}

export const AdditionalContextPanel = ({additionalContextsResponse, requestDetailsResponse}: AdditionalContextPanelProps) => {
    const additionalContexts: Array<any> = getAdditionalContextWithoutOtherChanges(additionalContextsResponse?.additionalContexts);

    if (!additionalContexts || additionalContexts.length == 0) {
        return (<Text>No additional context provided</Text>);
    }

    const securityMetadata = requestDetailsResponse?.securityMetadata?.securityMetadata;
    const displayNameAdditionalContexts: Array<any> = additionalContextsWithDisplayNames(additionalContexts, securityMetadata);
    const additionalContextsStyling = useStyleSheet(AdditionalContextPanelStyleSheet, null);

    return (
        <>
            {
                displayNameAdditionalContexts?.map(displayNameAdditionalContexts => (
                    <TeamsTable>
                        <tbody>
                        <tr style={{color: 'var(--gs-uitk-color-scheme-primary)'}}>
                            <TeamsTableCell>
                                {displayNameAdditionalContexts?.displayNames?.map((displayNames: String, index: number) => (
                                    <strong>{displayNames}{returnCommaIfNotLastValue(index, displayNameAdditionalContexts?.displayNames.length)}</strong>
                                ))}
                            </TeamsTableCell>
                        </tr>
                        {displayNameAdditionalContexts?.context && Object.entries(displayNameAdditionalContexts?.context)?.map(([key, value]) => (
                            <tr>
                                <TeamsTableCell>
                                    <strong>{key}:</strong>
                                    <br/>
                                    <div className={additionalContextsStyling.contexts}>
                                        {parse("<div>" + value as string + "</div>")}
                                    </div>
                                </TeamsTableCell>
                            </tr>
                        ))}
                        </tbody>
                    </TeamsTable>
                ))
            }
        </>
    );
};
