import {StyleSheet} from '@gs-ux-uitoolkit-react/style';
import {typographyVariants} from '@gs-ux-uitoolkit-react/design-system';

export const queryGridStyleSheet = new StyleSheet('queryGridStyleSheet', {
    itemHeading: {
        backgroundColor: 'var(--gs-uitk-color-scheme-secondary)'
    },
    itemRow: {
        ...typographyVariants.heading06

    }
})
export const wizardStyleSheet = new StyleSheet('button-override', {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',

        '*': {
            boxSizing: 'border-box',
        },
    },
    steps: {
        width: '100%',
        maxWidth: '640px',
    },
    footer: {
        padding: '12px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        maxWidth: '640px',

        '& *': {
            marginRight: '8px',

            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    buttonWithCheckbox: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginRight: '20px',
        padding: '0px 0'
    },
    checkbox: {
        marginRight: '20px'
    },
    alert: {
        paddingRight: '110px',
        marginBottom: '8px'
    }
});

export const dataChangeTableStyleSheet = new StyleSheet('data-change-table', {
    table: {
        marginTop: '12px',
        marginBottom: '12px',
        borderSpacing: '0px'
    },
    headerCell: {
        padding: '5px 5px 5px 0px',
        textAlign: 'left',
        fontWeight: 'bold',
        borderBottomWidth: '3px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'var(--gs-uitk-color-scheme-primary)'
    },
    securityNameCell: {
        padding: '5px 5px 5px 0px',
        textAlign: 'left',
        fontWeight: 'bold',
        color: 'var(--gs-uitk-color-scheme-primary)',
        borderBottomWidth: '3px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'var(--gs-uitk-color-scheme-primary)'
    },
    tableCell: {
        padding: '5px',
        textAlign: 'left'
    },
    zebraStripedRow: {
        // required otherwise there is weird spacing in the zebra stripes
        borderSpacing: '0px',
        '&:nth-child(odd)': {
            backgroundColor: 'var(--gs-uitk-color-scheme-secondary)'
        }
    },
    convictionListBadge: {
        marginLeft: '5px'
    },
    tableCellWIPColoredText: {
        padding: '5px',
        textAlign: 'left',
        color: 'var(--gs-uitk-color-status-success-bold)'
    },
    banner: {
        backgroundColor: 'var(--gs-uitk-color-core-blue-subtle)',
        padding: '8px'
    }
});

export const dataChangeSummaryStyleSheet = new StyleSheet('data-change-summary', {
    summaryCell: {
        padding: '5px 5px 5px 0px',
        textAlign: 'left',
        fontWeight: 'bold',
        color: 'var(--gs-uitk-color-scheme-primary)',
    },
    summaryChangeTitle: {
        textOverflow: 'auto',
        width: "-moz-fit-content",
        fontSize: "14px",
        fontWeight: "bold",
        padding: '0px 0px 5px 0px'

    },
    summaryChangeBody: {
        width: "-moz-fit-content",
        padding: '0px 0px 5px 0px',
        marginLeft: '0px',
        textOverflow: 'auto',
    }
});

export const   approvalCommentStyleSheet = new StyleSheet('approval-comment', {
    editActionButton: {
        width: '4em',
        display: 'inline-block',
        minWidth: '25%',
        paddingTop: '5em',
    },
    editActionButtonShortComment: {
        width: '4em',
        display: 'inline-block',
        minWidth: '25%',
        paddingTop: '2.5em',
    },
    readOnlyCommentBox: {
        resize: 'none',
        ... typographyVariants.body02,
        display: 'inline-block',
        border: '1px solid gray',
        opacity: '75%',
        color: 'var(--gs-uitk-color-scheme-primary)',
        background: 'inherit',
        borderRadius: '3px',
        overflowY: "scroll",
        padding: '10px 10px 15px 10px',
        minHeight: 'fit-content',
        minWidth: 'fit-content',
        maxWidth: '100%',
        width: '80%',
    },
    commentBox: {
        resize: 'none',
        ... typographyVariants.body02,
        display: 'inline-block',
        border: '1px solid gray',
        borderRadius: '3px',
        color: 'var(--gs-uitk-color-scheme-primary)',
        background: 'inherit',
        overflowY: "scroll",
        padding: '10px 10px 15px 10px',
        minHeight: 'fit-content',
        minWidth: 'fit-content',
        maxWidth: '100%',
        width: '80%',
    },
    mobileCommentBox: {
        ... typographyVariants.body02,
        border: '1px solid gray',
        borderRadius: '3px',
        color: 'var(--gs-uitk-color-scheme-primary)',
        background: 'inherit',
        padding: '10px 10px 10px 10px',
        minHeight: 'fit-content',
        maxHeight: '100%',
        height: 'fit-content',
        minWidth: 'fit-content',
        maxWidth: '80%',
        width: '80%'
    },
    readOnlyMobileCommentBox: {
        ... typographyVariants.body02,
        border: '1px solid gray',
        borderRadius: '3px',
        opacity: '75%',
        color: 'var(--gs-uitk-color-scheme-primary)',
        background: 'inherit',
        padding: '10px 10px 10px 10px',
        minHeight: 'fit-content',
        maxHeight: '100%',
        height: 'fit-content',
        minWidth: 'fit-content',
        maxWidth: '80%',
        width: '80%'
    },
});

export const requestFooterStyleSheet = new StyleSheet('request-footer', {
    footer: {
        backgroundColor: 'var(--gs-uitk-color-core-blue-subtle)'
    },
    buttonContainer: {
        marginTop: '1em',
        justifyContent: 'center',
        '&#collaborators-chat-button': {
            marginBottom: '1em', // only increasing bottom on the top bottom to separate the two buttons
            display: 'flex' // this needs to be a more specific css selector than the column container
        },
        '&#irc-chat-button': {
            display: 'flex' // this needs to be a more specific css selector than the column container
        }
    }
});

export const additionalActionButtons =  new StyleSheet('additional-actions', {
    hamburger: {
        float: 'right',
        color: 'var(--gs-uitk-color-surface-primary-text)'
    },
    menuOption: {
        // Due to a bug in UIToolkit causing the dropdown menu to not attach to the button, we have downgraded our dropdown version
        // However, the older version is unable to integrate with the UI Toolkit design systems, so we have to manually set colors for dark mode
        // We can delete once bug is fixed. Ticket: https://jira.work.gs.com/browse/UX-20454
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#131c23',
            color: '#FFFFFF'
        }
    }
});
