import {Text} from "@gs-ux-uitoolkit-react/text";
import React from "react";
import {
    getAdditionalContextForOtherChange, returnCommaIfNotLastValue,
} from "../../../services/utils/AdditionalContextFormatter";
import {TeamsTable} from "../../Table/TeamsTable";
import {TeamsTableCell} from "../../Table/TeamsTableCell";
import parse from "html-react-parser";
import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {AdditionalContextPanelStyleSheet} from "../AdditionalContext/AdditionalContextPanelStyleSheet";
import {additionalContextsWithDisplayNames} from "../../../services/utils/AdditionalContextFormatter";

interface NonQuantumChangesPanelProps {
    additionalContextsResponse: any;
    requestDetailsResponse: any;
}

export const NonQuantumChangesPanel = ({
                                           additionalContextsResponse, requestDetailsResponse
                                       }: NonQuantumChangesPanelProps) => {
    const additionalContexts: Array<any> = getAdditionalContextForOtherChange(additionalContextsResponse?.additionalContexts);

    if (additionalContexts?.length == 0) {
        return (<Text>No context for other changes provided</Text>);
    }

    const securityMetadata = requestDetailsResponse?.securityMetadata?.securityMetadata;
    const displayNameAdditionalContexts: Array<any> = additionalContextsWithDisplayNames(additionalContexts, securityMetadata);
    const additionalContextsStyling = useStyleSheet(AdditionalContextPanelStyleSheet, null);

    return (
        <>
            {
                displayNameAdditionalContexts?.map(displayNameAdditionalContexts => (
                    <TeamsTable>
                        <tbody>
                        <tr style={{color: 'var(--gs-uitk-color-scheme-primary)'}}>
                            <TeamsTableCell>
                                {displayNameAdditionalContexts?.displayNames?.map((displayNames: String, index: number) => (
                                    <strong>{displayNames}{returnCommaIfNotLastValue(index, displayNameAdditionalContexts?.displayNames.length)}</strong>
                                ))}
                            </TeamsTableCell>
                        </tr>
                        {displayNameAdditionalContexts?.context && Object.entries(displayNameAdditionalContexts?.context)?.map(([key, value]) => (
                            <tr>
                                <TeamsTableCell>
                                    <div className={additionalContextsStyling.contexts}>
                                        {parse("<div>" + value as string + "</div>")}
                                    </div>
                                </TeamsTableCell>
                            </tr>
                        ))}
                        </tbody>
                    </TeamsTable>
                ))
            }
        </>
    );
};
