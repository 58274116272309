import React, {useEffect, useState} from 'react';
import {Accordion, AccordionPanel} from '@gs-ux-uitoolkit-react/accordion';
import {RequestHeader} from './RequestHeader/RequestHeader';
import {DetailsPanel} from './RequestDetails/DetailsPanel';
import {useQuery} from '@apollo/client';
import {RequestFooter} from './RequestFooter/RequestFooter';
import {GET_REQUEST_DETAILS, GET_WIP_DATA, GET_ADDITIONAL_CONTEXT} from '../../constants/GraphqlCallDefinitions';
import {AdditionalContextPanel} from './AdditionalContext/AdditionalContextPanel';
import {DataChangePanel} from './DataChanges/DataChangePanel';
import {AttachmentsPanel} from "./AttachmentsPanel/AttachmentsPanel";
import {reportToEUM} from "../../services/EUMService";
import {getAttachmentCount} from "../../services/AttachmentService";
import {getDataChangeCount} from "../../services/utils/DataChangeService";
import {ApprovalDetailsPanel} from "./RequestDetails/ApprovalDetailsPanel";
import {getConvictionListChangeCount} from "../../services/utils/ConvictionListService";
import {ConvictionListChangesPanel} from './ConvictionListChanges/ConvictionListChangesPanel';
import {NonQuantumChangesPanel} from "./DataChanges/NonQuantumChangesPanel";

const REQUEST_DETAILS = 'Request Details';
const APPROVAL_DETAILS = 'Approval Details';
const DATA_CHANGES = 'Data Changes';
const CONVICTION_LIST_CHANGES = 'Conviction List Changes';
const ADDITIONAL_CONTEXT = 'Additional Context';
const ATTACHMENTS = 'Attachments';
const OTHER_CHANGES = 'Other / Non-Quantum Data Changes';

export const Request = () => {
    const pollInterval = 5_000
    const [requestHasApprovals, setRequestHasApprovals] = useState(false);
    const requestDetailsQuery = useQuery(GET_REQUEST_DETAILS);
    const additionalContext = useQuery(GET_ADDITIONAL_CONTEXT);
    const {data} = useQuery(GET_WIP_DATA, {
            pollInterval
        }
    );

    const onCollapse = (panelName: string) => onTogglePanel(panelName, 'collapse');
    const onExpand = (panelName: string) => onTogglePanel(panelName, 'expand');

    const onTogglePanel = (panelName: string, actionName: string) => {
        const requestId = requestDetailsQuery?.data?.currentRequestId;
        reportToEUM(`Clicked to ${actionName} panel ${panelName} for request ${requestId}`, {requestId});
    };

    useEffect(() => {
        additionalContext.refetch();
    }, [requestDetailsQuery?.data?.getIRCRequest?.lastUpdated]);

    useEffect(() => {
        if (requestDetailsQuery.data?.getIRCRequest?.approvedBy?.length > 0) {
            setRequestHasApprovals(true);
        } else {
            setRequestHasApprovals(false);
        }
    }, [requestDetailsQuery.data?.getIRCRequest?.approvedBy]);


    return (
        <div>
            <RequestHeader/>
            <Accordion emphasis='bold'>
                <AccordionPanel
                    header={REQUEST_DETAILS}
                    defaultExpanded
                    onCollapse={() => onCollapse(REQUEST_DETAILS)}
                    onExpand={() => onExpand(REQUEST_DETAILS)}
                >
                    <DetailsPanel response={requestDetailsQuery?.data?.getIRCRequest}/>
                </AccordionPanel>
                {requestHasApprovals &&
                    <AccordionPanel
                        header={APPROVAL_DETAILS}
                        defaultExpanded
                        onCollapse={() => onCollapse(APPROVAL_DETAILS)}
                        onExpand={() => onExpand(APPROVAL_DETAILS)}
                    >
                        <ApprovalDetailsPanel response={requestDetailsQuery?.data?.getIRCRequest}/>
                    </AccordionPanel>
                }
                <AccordionPanel
                    header={`${DATA_CHANGES} (${getDataChangeCount(data?.getRequestQuantumData)})`}
                    onCollapse={() => onCollapse(DATA_CHANGES)}
                    onExpand={() => onExpand(DATA_CHANGES)}
                >
                    <DataChangePanel response={data?.getRequestQuantumData}
                                     changeTypes={requestDetailsQuery?.data?.getIRCRequest?.ircSubmission?.ircChangeTypes}/>
                </AccordionPanel>
                <AccordionPanel
                    header={OTHER_CHANGES}
                    onCollapse={() => onCollapse(OTHER_CHANGES)}
                    onExpand={() => onExpand(OTHER_CHANGES)}
                >
                    <NonQuantumChangesPanel additionalContextsResponse={additionalContext?.data?.getAdditionalContext}
                                            requestDetailsResponse={requestDetailsQuery?.data?.getIRCRequest}/>
                </AccordionPanel>
                <AccordionPanel
                    header={`${CONVICTION_LIST_CHANGES} (${getConvictionListChangeCount(data?.getRequestQuantumData)})`}
                    onCollapse={() => onCollapse(CONVICTION_LIST_CHANGES)}
                    onExpand={() => onExpand(CONVICTION_LIST_CHANGES)}
                >
                    <ConvictionListChangesPanel response={data?.getRequestQuantumData}/>
                </AccordionPanel>
                <AccordionPanel
                    header={ADDITIONAL_CONTEXT}
                    onCollapse={() => onCollapse(ADDITIONAL_CONTEXT)}
                    onExpand={() => onExpand(ADDITIONAL_CONTEXT)}
                >
                    <AdditionalContextPanel additionalContextsResponse={additionalContext?.data?.getAdditionalContext}
                                            requestDetailsResponse={requestDetailsQuery?.data?.getIRCRequest}
                    />
                </AccordionPanel>
                <AccordionPanel
                    header={`${ATTACHMENTS} (${getAttachmentCount(requestDetailsQuery?.data)})`}
                    onCollapse={() => onCollapse(ATTACHMENTS)}
                    onExpand={() => onExpand(ATTACHMENTS)}
                >
                    <AttachmentsPanel requestDetails={requestDetailsQuery?.data?.getIRCRequest}/>
                </AccordionPanel>
            </Accordion>
            <RequestFooter/>
        </div>
    );
};
