import {Rating, ratingToRatingDisplayName} from "../../constants/Rating";
import {CONVICTION_LIST, CONVICTION_LIST_VALUE} from "../../constants/ConvictionList";

interface DataChangesSummaryProps {
    involvedChanges: {}[],
    issuerName: string,
    ticker: string
}

export function findArrayElementByTitle(array: any[], title: string) {
    return array.find((element: { keyword: any; }) => {
        return element.keyword === title;
    })
}

export function getMALiveValue(data: DataChangesSummaryProps): string {
    return findArrayElementByTitle(data.involvedChanges, "MA_PROB")?.liveValue;
}

export function getMAWipValue(data: DataChangesSummaryProps): string {
    return findArrayElementByTitle(data.involvedChanges, "MA_PROB")?.wipValue;
}

export function getTargetPriceLiveValue(data: DataChangesSummaryProps): string {
    return roundDecimals(findArrayElementByTitle(data.involvedChanges, "TARGET_PRICE")?.liveValue);
}

export function getTargetPriceWipValue(data : DataChangesSummaryProps) : string {
    return roundDecimals(findArrayElementByTitle(data.involvedChanges, "TARGET_PRICE")?.wipValue);
}

function getRatingWipValue(data: DataChangesSummaryProps): Rating {
    return findArrayElementByTitle(data.involvedChanges, "RATING")?.wipValue;
}

export function getRatingWipValueDisplayName(data: DataChangesSummaryProps): string {
    return ratingToRatingDisplayName[getRatingWipValue(data)] ? ratingToRatingDisplayName[getRatingWipValue(data)] : getRatingWipValue(data);
}

function getRatingLiveValue(data: DataChangesSummaryProps): Rating {
    return findArrayElementByTitle(data.involvedChanges, "RATING")?.liveValue;
}

export function getRatingLiveValueDisplayName(data: DataChangesSummaryProps): string {
    return ratingToRatingDisplayName[getRatingLiveValue(data)] ? ratingToRatingDisplayName[getRatingLiveValue(data)] : getRatingLiveValue(data);
}

export function getIssuerAndTickerDisplayName(data: DataChangesSummaryProps): string {
    return data.issuerName + " (" + data.ticker + ")";
}

export const getDataChangeCount = (dataChanges: any) => {
    if (!dataChanges || dataChanges.length === 0) {
        return 0;
    }

    return dataChanges.filter((dataChange: any) => (!dataChange.changeTypes.includes(CONVICTION_LIST)
        || dataChange.changeTypes.includes(CONVICTION_LIST) && dataChange.changeTypes.length > 1)).length;
};

function roundDecimals(number: string): string {
    if ((number == null) || (number == '') || isNaN(Number(number))) {
        return '';
    }
    return String(Number(number).toFixed(2));
}
