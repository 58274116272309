import {GET_ADDITIONAL_CONTEXT, GET_REQUEST_DETAILS} from "../../../constants/GraphqlCallDefinitions";
import {useQuery} from "@apollo/client";
import {AccordionPanel} from "@gs-ux-uitoolkit-react/accordion";
import {
    getAdditionalContextForOtherChange,
} from "../../../services/utils/AdditionalContextFormatter";
import {NonQuantumChangesPanel} from "../../Request/DataChanges/NonQuantumChangesPanel";
import React from "react";
import {Text} from "@gs-ux-uitoolkit-react/text";

export const NonQuantumChangesSummary = () => {
    const { data: additionalContextData } = useQuery(GET_ADDITIONAL_CONTEXT);
    const requestDetailsQuery = useQuery(GET_REQUEST_DETAILS);
    const nonQuantumChangeList = getAdditionalContextForOtherChange(additionalContextData?.getAdditionalContext?.additionalContexts);

    return (
        <AccordionPanel defaultExpanded header={"Other Changes"}>
            <NonQuantumChangesPanel additionalContextsResponse={additionalContextData?.getAdditionalContext}
                                    requestDetailsResponse={requestDetailsQuery?.data?.getIRCRequest}/>
        </AccordionPanel>
    );
};
